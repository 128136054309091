<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field reservationDate">
            <div class="title">전송일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  v-model="inputDateRangeValue"
                  type="lookup-condition"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">수신핸드폰</div>
            <ul class="content">
              <li class="item">
                <input-text v-model="searchConditions.rcverInfo" />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">내용</div>
            <ul class="content">
              <li class="item">
                <input-text v-model="searchConditions.messageContents" />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              v-on:click.native="onSmsSendStatusViewClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              @click.native="onSearchDetailPopupOpen"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">SMS전송현황 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="summary">
              <div class="item">
                <div class="content">
                  <div
                    :class="[
                      'item success',
                      filterSmsSuccessType.length > 0 && 'active',
                    ]"
                    @click="onClickGridFiltering(true)"
                  >
                    <div class="count">
                      {{ smsSendStatus.successCount | numberWithCommas }}
                    </div>
                    <div class="caption">
                      성공
                    </div>
                  </div>
                  <div
                    :class="[
                      'item',
                      filterSmsSuccessType.includes('SMS') && 'active',
                    ]"
                    @click="onClickGridFiltering(true, 'SMS')"
                  >
                    <div class="count">
                      {{ smsSendStatus.successSmsCount | numberWithCommas }}
                    </div>
                    <div class="caption">
                      SMS
                    </div>
                  </div>
                  <div
                    :class="[
                      'item',
                      filterSmsSuccessType.includes('MMS') && 'active',
                    ]"
                    @click="onClickGridFiltering(true, 'MMS')"
                  >
                    <div class="count">
                      {{ smsSendStatus.successMmsCount | numberWithCommas }}
                    </div>
                    <div class="caption">
                      MMS
                    </div>
                  </div>
                  <div
                    :class="[
                      'item',
                      filterSmsSuccessType.includes('AT') && 'active',
                    ]"
                    @click="onClickGridFiltering(true, 'AT')"
                  >
                    <div class="count">
                      {{ smsSendStatus.successAtCount | numberWithCommas }}
                    </div>
                    <div class="caption">
                      카카오
                    </div>
                  </div>
                </div>
              </div>
              <div class="item divider" />
              <div class="item">
                <div class="content">
                  <div
                    :class="[
                      'item fail',
                      filterSmsFailType.length > 0 && 'active',
                    ]"
                    @click="onClickGridFiltering(false)"
                  >
                    <div class="count">
                      {{ smsSendStatus.failCount | numberWithCommas }}
                    </div>
                    <div class="caption">
                      실패
                    </div>
                  </div>
                  <div
                    :class="[
                      'item',
                      filterSmsFailType.includes('SMS') && 'active',
                    ]"
                    @click="onClickGridFiltering(false, 'SMS')"
                  >
                    <div class="count">
                      {{ smsSendStatus.failSmsCount | numberWithCommas }}
                    </div>
                    <div class="caption">
                      SMS
                    </div>
                  </div>
                  <div
                    :class="[
                      'item',
                      filterSmsFailType.includes('MMS') && 'active',
                    ]"
                    @click="onClickGridFiltering(false, 'MMS')"
                  >
                    <div class="count">
                      {{ smsSendStatus.failMmsCount | numberWithCommas }}
                    </div>
                    <div class="caption">
                      MMS
                    </div>
                  </div>
                  <div
                    :class="[
                      'item',
                      filterSmsFailType.includes('AT') && 'active',
                    ]"
                    @click="onClickGridFiltering(false, 'AT')"
                  >
                    <div class="count">
                      {{ smsSendStatus.failAtCount | numberWithCommas }}
                    </div>
                    <div class="caption">
                      카카오
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li>
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-shortcut-button="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="smsSendStatus.shortcuts.send"
                      :shortcut="{key: 'F7'}"
                      v-on:click.native="onSmsSendButtonClicked"
                  >
                    SMS전송
                  </erp-button>
                </li>

                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body" style="border: none">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="smsSendStatusListGrid"
                :provides="grid"
                :dataSource="smsSendStatusList"
                :columns="smsSendStatusListGridColumn"
                :selectionSettings="selectionSettings"
                :pageSettings="smsStatusGridPageSettings"
                :allowPaging="true"
                :allowResizing="true"
                :allowExcelExport="true"
                @headerCellInfo="headerCellInfo"
                @queryCellInfo="queryCellInfo"
                @recordClick="onGridRecordClick"
                @actionComplete="smsSendStatusListGridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <ejs-dialog
      ref="searchDetailPopup"
      :header="`상세검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="false"
      :visible="false"
      width="387"
      :animationSettings="{ effect: 'None' }"
      v-show="isSearchDetailPopupOpen"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">SMS발신처</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        ref="smsSenderMultiSelect"
                        cssClass="lookupDetail-condition-multiselect"
                        placeHolder="전체"
                        v-model="searchConditions.smsSenderList"
                        :dataSource="searchOptions.smsSenderOptions"
                        :fields="commonCodeFields"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">SMS종류</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        ref="smsKindMultiSelect"
                        cssClass="lookupDetail-condition-multiselect"
                        placeHolder="전체"
                        v-model="searchConditions.smsKindList"
                        :dataSource="searchOptions.smsKindOptions"
                        :fields="commonCodeFields"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">전송타입</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-multiselect
                        ref="smsKindMultiSelect"
                        cssClass="lookupDetail-condition-multiselect"
                        placeHolder="전체"
                        v-model="searchConditions.smsSendTypeList"
                        :dataSource="searchOptions.smsSendTypeOptions"
                        :fields="commonCodeFields"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title"></div>
                  <ul class="content">
                    <li class="item">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                              type="checkbox"
                              id="sendFailViewFlag"
                              v-model="searchConditions.sendFailViewFlag"
                              value="true"
                            />
                            <i></i>
                            <div class="label">전송대기&실패 조회</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  button-div="GET"
                  :ignore="isPopupOpened"
                  :is-shortcut-button="true"
                  v-on:click.native="onSmsSendStatusDetailViewClicked"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="onInitButtonClicked">
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onSearchDetailPopupClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <sms-send-popup
      v-if="isSmsSendPopupOpen"
      ref="smsSendPopup"
      @popupClosed="smsSendPopupClosed"
    />
  </div>
</template>

<style scoped>
/* 상단 집계 퍼블리싱 */
body .section-header {position: relative}
body .section-header > .summary {display: flex; align-items: center; justify-content: space-between; position: absolute; top: 8px; right: 200px; width: 510px; height: 36px}
body .section-header > .summary > .item,
body .section-header > .summary > .item > .content {display: flex; height: inherit; cursor: pointer}
body .section-header > .summary > .item.divider {width: 1px; height: 20px; margin-top: -8px; margin-left: 3px; background-color: #ccc}
body .section-header > .summary > .item > .content > .item {display: flex; flex-direction: column; align-items: center; justify-content: flex-end; width: 58px; height: calc(100% - 8px); margin-left: 3px; position: relative; color: #333; border: 1px solid #e0e0e0; border-radius: 3px; background-color: #fff}
body .section-header > .summary > .item > .content > .item > .count {position: absolute; top: -8px; right: -4px; width: fit-content; height: 15px; padding: 1px 6px 0 5px; color: #333; font-size: 11px; font-weight: bold; border-radius: 500rem; background-color: #9999bb66; z-index: 1}
body .section-header > .summary > .item > .content > .item > .caption {font-size: 11px}
body .section-header > .summary > .item > .content > .item.success {border-color: #D9E5FF; background-color: #D9E5FF}
body .section-header > .summary > .item > .content > .item.fail {border-color: #FFD8D8; background-color: #FFD8D8}
body .section-header > .summary > .item > .content > .item.active {border-color: #e0e0e0; background-color: #FFFFCC}
body .section-header > .summary > .item > .content > .item.success.active {border-color: #B2CCFF; background-color: #B2CCFF}
body .section-header > .summary > .item > .content > .item.fail.active {border-color: #FFA7A7; background-color: #FFA7A7}
body .section-header > .summary > .item > .content > .item.success > .caption,
body .section-header > .summary > .item > .content > .item.fail > .caption {font-weight: bold}
</style>

<script>
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {
  Filter,
  Resize,
  Group,
  ExcelExport,
  ForeignKey,
  VirtualScroll,
  Page,
} from "@syncfusion/ej2-vue-grids";
import { MultiSelect, CheckBoxSelection } from "@syncfusion/ej2-dropdowns";
import { getFormattedPhoneNumber } from "@/utils/string";
import { numberWithCommas } from "@/utils/number";
import { getFormattedDate } from "@/utils/date";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import { SHORTCUT_KEYS } from "@/utils/KeyboardUtil";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import smsSendPopup from "../common/SmsSendPopup";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton.vue";

MultiSelect.Inject(CheckBoxSelection);

export default {
  name: "SmsSendStatus",
  components: {
    InputText,
    InputDateRange,
    ejsGridWrapper,
    smsSendPopup,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  filters: {numberWithCommas},
  data() {
    return {
      count: 0,
      filterSmsSuccessType: [],
      filterSmsFailType: [],
      isSearchDetailPopupOpen: false,
      isSmsSendPopupOpen: false,
      grid: [
        Filter,
        Resize,
        Group,
        ExcelExport,
        ForeignKey,
        VirtualScroll,
        Page,
      ],
      selectionSettings: { type: "Single", mode: "Both", enableToggle: false },
      smsStatusGridPageSettings: { pageSize: 50 },
      commonCodeFields: { text: "comName", value: "comCode" },
      inputDateRangeValue: { to: null, from: null },
      searchConditions: {
        sendDateFrom: null,
        sendDateTo: null,
        rcverInfo: null,
        messageContents: null,
        sendFailViewFlag: false,
        smsSenderList: [],
        smsKindList: [],
        smsSendTypeList: []
      },
      searchOptions: {
        smsSenderOptions: null,
        smsKindOptions: null,
        smsSendTypeOptions: null
      },
      pureSmsSendStatusList: [],
      smsSendStatusList: [],
      smsSendStatus: {
        successCount: 0,
        successSmsCount: 0,
        successMmsCount: 0,
        successAtCount: 0,
        failCount: 0,
        failSmsCount: 0,
        failMmsCount: 0,
        failAtCount: 0,
      },
      smsSendStatusListGridColumn: [
        {
          field: "sendId",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "sendDate",
          headerText: "전송일자",
          width: 90,
          textAlign: "center",
          type: "string",
        },
        {
          field: "sendTime",
          headerText: "전송시간",
          width: 90,
          textAlign: "center",
          type: "string",
        },
        {
          field: "smsSendType",
          headerText: "전송타입",
          width: 120,
          textAlign: "center",
          groupCode: "SMS_SEND_TYPE",
          isCommonCodeField: true,
          type: "string",
        },
        {
          field: "smsSender",
          headerText: "SMS발신처",
          width: 100,
          textAlign: "center",
          groupCode: "SMS_SENDER",
          isCommonCodeField: true,
          type: "string",
        },
        {
          field: "rcverName",
          headerText: "수신자명",
          width: 120,
          type: "string",
        },
        {
          field: "rcverInfo",
          headerText: "수신번호",
          width: 120,
          textAlign: "center",
          valueAccessor: this.telNumberValueAccess,
          type: "string",
        },
        {
          field: "dsptchNo",
          headerText: "발신번호",
          width: 120,
          textAlign: "center",
          valueAccessor: this.telNumberValueAccess,
          type: "string",
        },
        {
          field: "smsKind",
          headerText: "시도SMS종류",
          width: 120,
          textAlign: "center",
          groupCode: "SMS_KIND",
          isCommonCodeField: true,
          type: "string",
        },
        {
          field: "resultDiv",
          headerText: "결과SMS종류",
          width: 120,
          textAlign: "center",
          groupCode: "SMS_KIND",
          isCommonCodeField: true,
          type: "string",
        },
        {
          field: "resultDescription",
          headerText: "전송결과",
          width: 250,
          type: "string",
        },
        {
          field: "successResult",
          headerText: "성공 여부",
          width: 90,
          textAlign: "center",
          type: "string",
        },
        {
          field: "messageContents",
          headerText: "메시지내용",
          width: 250,
          type: "string",
        },
        {
          field: "insertName",
          headerText: "전송자",
          width: 80,
          type: "string"
        },
        {
          field: "insertDt",
          headerText: "전송일시",
          width: 140,
          textAlign: "center",
          type: "string"
        }
      ],
    };
  },
  watch: {
    $route() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
  },
  async created() {
    this.inputDateRangeValue.from = getFormattedDate(new Date());
    this.inputDateRangeValue.to = getFormattedDate(new Date());
    this.searchOptions.smsSenderOptions = commonCodesGetCommonCode(
      "SMS_SENDER",
      true
    );
    this.searchOptions.smsKindOptions = commonCodesGetCommonCode(
      "SMS_KIND",
      true
    );
    this.searchOptions.smsSendTypeOptions = commonCodesGetCommonCode("SMS_SEND_TYPE", true);
  },
  computed: {
    isPopupOpened() {
      return this.isSmsSendPopupOpen;
    },
    sendSmsButtonProps() {
      return {
        shortcutKey: "SmsSendStatus.shortcuts.sendSms",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F7,
        },
      };
    },
  },
  methods: {
    telNumberValueAccess(field, data) {
      let tel = data[field];
      if (tel != null) {
        tel = getFormattedPhoneNumber(tel);
      }
      return tel;
    },
    onSmsSendStatusViewClicked() {
      this.fetchSmsSendInfos();
    },
    onSearchDetailPopupOpen() {
      this.isSearchDetailPopupOpen = true;
      this.$refs.searchDetailPopup.show();
    },
    onSmsSendButtonClicked() {
      this.isSmsSendPopupOpen = true;
      const smsSendData = {
        smsSendType: "NOTE",
        rcverName: null,
        rcverInfo: null,
        sendKey: "0",
      };
      this.$nextTick(() => {
        this.$refs.smsSendPopup.showSmsSendPopup(smsSendData);
      });
    },
    headerCellInfo(args) {
      const column = args.cell.column.field;
      if (
        column === "rcverName" ||
        column === "resultDescription" ||
        column === "messageContents"
      ) {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    queryCellInfo(args) {
      const column = args.column.field;
      if (
        column === "rcverName" ||
        column === "resultDescription" ||
        column === "messageContents"
      ) {
        args.cell.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    onSmsSendStatusDetailViewClicked() {
      this.fetchSmsSendInfos();
    },
    onInitButtonClicked() {
      this.searchConditions.sendFailViewFlag = false;

      this.searchConditions.smsSenderList = [];
      this.searchOptions.smsSenderOptions.forEach((data) => {
        this.searchConditions.smsSenderList.push(data.comCode);
      });

      this.searchConditions.smsKindList = [];
      this.searchOptions.smsKindOptions.forEach((data) => {
        this.searchConditions.smsKindList.push(data.comCode);
      });
    },
    onSearchDetailPopupClose() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    async fetchSmsSendInfos() {
      this.searchConditions.sendDateFrom = this.inputDateRangeValue.from;
      this.searchConditions.sendDateTo = this.inputDateRangeValue.to;

      if (!this.searchConditions.sendDateFrom) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["전송일자"])
        );
      }
      if (!this.searchConditions.sendDateTo) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["종료일자"])
        );
      }
      const args = JSON.parse(JSON.stringify(this.searchConditions));
      const data = await GolfErpAPI.fetchSmsSendInfos({
        sendDateFrom: args.sendDateFrom,
        sendDateTo: args.sendDateTo,
        rcverInfo: args.rcverInfo || undefined,
        messageContents: args.messageContents || undefined,
        smsSenderList: args.smsSenderList,
        smsKindList: args.smsKindList,
        sendFailViewFlag: args.sendFailViewFlag,
        smsSendTypeList: args.smsSendTypeList
      });
      this.pureSmsSendStatusList = this.smsSendStatusList = data.smsSendList.map(data => ({
        ...data,
        successResult:
          !data.resultCode
            ? null
            : (data.succesFlag ? "성공" : "실패")
      }));
      const successedSmsLSendist = data.smsSendList
        ?.filter(item => item.succesFlag);
      const failedSmsLSendist = data.smsSendList
        ?.filter(item => !item.succesFlag);
      this.smsSendStatus = {
        successCount: successedSmsLSendist?.length,
        successSmsCount: successedSmsLSendist
          ?.filter(item => item.resultDiv === 'SMS')
          ?.length,
        successMmsCount: successedSmsLSendist
          ?.filter(item => item.resultDiv === 'MMS')
          ?.length,
        successAtCount: successedSmsLSendist
          ?.filter(item => item.resultDiv === 'AT')
          ?.length,
        failCount: failedSmsLSendist?.length,
        failSmsCount: failedSmsLSendist
          ?.filter(item => item.smsKind === 'SMS')
          ?.length,
        failMmsCount: failedSmsLSendist
          ?.filter(item => item.smsKind === 'MMS')
          ?.length,
        failAtCount: failedSmsLSendist
          ?.filter(item => item.smsKind === 'AT')
          ?.length,
      };
      this.filterSmsSuccessType = [];
      this.filterSmsFailType = [];
    },
    smsSendPopupClosed() {
      this.isSmsSendPopupOpen = false;
    },
    onGridRecordClick(args) {
      const {
        column: {
          field
        },
        rowData
      } = args;

      if (
        field === "rcverName" ||
        field === "resultDescription" ||
        field === "messageContents"
      ) {
        this.isSmsSendPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.smsSendPopup.showSmsSendPopup(rowData);
        });
      }
    },
    smsSendStatusListGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.smsSendStatusListGrid?.getGridBatchCount() || 0
      );
    },
    onClickExcel() {
      this.$refs.smsSendStatusListGrid.excelExport();
    },
    onClickGridFiltering(isSuccess = null, smsType = null) {
      if (smsType) {
        if (isSuccess === true) {
          const hasSmsType = this.filterSmsSuccessType.includes(smsType);
          if (hasSmsType) {
            this.filterSmsSuccessType = this.filterSmsSuccessType
              ?.filter(item => item !== smsType);
          } else {
            this.filterSmsSuccessType.push(smsType);
          }
        } else if (isSuccess === false) {
          const hasSmsType = this.filterSmsFailType.includes(smsType);
          if (hasSmsType) {
            this.filterSmsFailType = this.filterSmsFailType
              ?.filter(item => item !== smsType);
          } else {
            this.filterSmsFailType.push(smsType);
          }
        }
      } else {
        const filterType = ['SMS', 'MMS', 'AT'];
        if (isSuccess === true) {
          if (this.filterSmsSuccessType.length < filterType.length) {
            this.filterSmsSuccessType = filterType;
          } else {
            this.filterSmsSuccessType = [];
          }
        } else if (isSuccess === false) {
          if (this.filterSmsFailType.length < filterType.length) {
            this.filterSmsFailType = filterType;
          } else {
            this.filterSmsFailType = [];
          }
        } else {
          this.filterSmsSuccessType = [];
          this.filterSmsFailType = [];
        }
      }
      let smsSendStatusList = [];
      if (
        this.filterSmsSuccessType.length > 0 ||
        this.filterSmsFailType.length > 0
      ) {
        this.pureSmsSendStatusList
          ?.filter(item => item.succesFlag === true)
          ?.filter(i => this.filterSmsSuccessType.includes(i.resultDiv))
          ?.map(i => smsSendStatusList.push(i));
        this.pureSmsSendStatusList
          ?.filter(item => item.succesFlag === false)
          ?.filter(i => this.filterSmsFailType.includes(i.smsKind))
          ?.map(i => smsSendStatusList.push(i));
      } else {
        smsSendStatusList = this.pureSmsSendStatusList;
      }
      this.smsSendStatusList = smsSendStatusList;
    },
  },
};
</script>
